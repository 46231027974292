<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"

    export let entry: FormEntry
    let secured = true
</script>


<div {...entry.rowAttr} class="flex flex-col">
    {#if entry.label !== null}
        <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
            {@html entry.label}
            {#if entry.required}
                <span class="absolute ml-1 text-red-800">*</span>
            {/if}
        </label>
    {/if}

    <div class="relative w-full mt-1">
        <input
                {...entry.attr}
                autocomplete="off"
                class="bg-white rounded-md shadow-sm px-4 py-2 focus-visible:outline-0 focus-visible:shadow-md w-full pr-14"
                disabled={entry.disabled ? 'disabled' : null}
                id={entry.id}
                name={entry.fullName}
                on:input={e => entry.value = e.target.value}
                on:input
                required={entry.required ? 'required' : null}
                type={secured ? "password" : "text"}
                value={entry.value}
        />

        <button on:click|preventDefault={() => secured = !secured} class="absolute top-0 bottom-0 right-0 w-12 flex items-center justify-center">
            {#if secured}
                <img src="/image/secured.svg" alt="secured icon"/>
            {:else}
                <img src="/image/secured_off.svg" alt="secured off icon"/>
            {/if}
        </button>
    </div>

    <FormTypeErrors errors={entry.errors}/>
</div>
