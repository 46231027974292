<script lang="ts">
    import { CloseButton, itemActions } from 'svelecte/item';
    import {WeatherStateTypeEnum} from "@shared/Form/WeatherTypeSelectType/WeatherStateTypeEnum"

    export let index: number = -1
    export let item: {label: string, value: WeatherStateTypeEnum}
    export let isSelected = false
    export let isMultiple = false

    let icon

    $: {
        switch (item.value) {
            case WeatherStateTypeEnum.CLEAR:
                icon = "jasno"
                break

            case WeatherStateTypeEnum.THUNDERSTORM:
                icon = "bourka"
                break

            case WeatherStateTypeEnum.SNOW:
                icon = "snezeni"
                break

            case WeatherStateTypeEnum.RAIN:
                icon = "dest"
                break

            case WeatherStateTypeEnum.PARTLY_CLOUDY:
                icon = "polojasno"
                break

            case WeatherStateTypeEnum.FOGGY:
                icon = "mlha"
                break

            case WeatherStateTypeEnum.WINDY:
                icon = "vetrno"
                break

            case WeatherStateTypeEnum.CLOUDY:
                icon = "zatazeno"
                break

            case WeatherStateTypeEnum.DRIZZLE:
                icon = "mrholeni"
                break

            default:
                icon = "neurceno"
        }
    }
</script>

<div class="sv-item" use:itemActions={{item, index}}
     class:is-selected={isSelected}
     on:select
     on:deselect
     on:hover
>
    <div class="sv-item-content flex gap-3">
        {#if icon}
            <img src="/image/weather/{icon}.svg" class="w-4 object-fit" alt="weather icon"/>
        {/if}

        <span>{item.label}</span>
    </div>
    {#if isSelected && isMultiple}
        <CloseButton/>
    {/if}
</div>