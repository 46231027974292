export enum WeatherStateTypeEnum {
    UNKNOWN = "WEATHER_STATE_TYPE_UNKNOWN",
    CLEAR = "WEATHER_STATE_TYPE_CLEAR",
    RAIN = "WEATHER_STATE_TYPE_RAIN",
    DRIZZLE = "WEATHER_STATE_TYPE_DRIZZLE",
    THUNDERSTORM = "WEATHER_STATE_TYPE_THUNDERSTORM",
    SNOW = "WEATHER_STATE_TYPE_SNOW",
    SLEET = "WEATHER_STATE_TYPE_SLEET",
    WINDY = "WEATHER_STATE_TYPE_WINDY",
    FOGGY = "WEATHER_STATE_TYPE_FOGGY",
    CLOUDY = "WEATHER_STATE_TYPE_CLOUDY",
    PARTLY_CLOUDY = "WEATHER_STATE_TYPE_PARTLY_CLOUDY",
}