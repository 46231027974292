<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import FormChildren from "@shared/Form/FormChildren.svelte"
    import {handleAddChildToCollection, handleRemoveChildFromCollection} from "@shared/Form/CollectionType/utils"
    import Translator from "bazinga-translator";

    export let entry: FormEntry

    $: children = entry.children !== null
            ? Array.isArray(entry.children)
                    ? entry.children
                    : Object.values(entry.children)
            : []
</script>


<div {...entry.rowAttr} class="flex flex-col {entry.rowAttr?.class ?? ''}">
    <div class="flex flex-row justify-between">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}

        <button class="bg-primary rounded-md px-3 py-0 text-black"
                tabindex="-1"
                on:click|preventDefault={() => entry.children = handleAddChildToCollection(entry)}
        >
            + <b class="text-sm">{Translator.trans("add")}</b>
        </button>
    </div>

    {#if children.length > 0}
        <div class="flex flex-col pt-3 gap-6">
            {#each children as child, index}
                <div class="flex flex-col border-l border-primary pl-4">
                    <div class="flex flex-row justify-between">
                        <span class="text-xs">{Translator.trans("item_number_x")}{(index + 1)}</span>
                        <button class="bg-red-500 rounded-md px-3 py-0 text-black"
                                tabindex="-1"
                                on:click|preventDefault={() => entry.children = handleRemoveChildFromCollection(children, index)}
                        >
                            <b class="text-sm">{Translator.trans("remove")}</b>
                        </button>
                    </div>
                    <div class="flex flex-col gap-6">
                        <FormChildren form={child}/>
                    </div>
                </div>
            {/each}
        </div>
    {/if}

    <FormTypeErrors errors={entry.errors}/>

    {#if entry.help}
        <small class="mt-3">{@html entry.help}</small>
    {/if}
</div>
