<script lang="ts">
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import {FormEntry} from "@shared/Form/interface"
    import tooltip from "@utils/Action/Tooltip"
    import {clickOutside} from "svelte-use-click-outside"
    import {createEventDispatcher, onMount} from "svelte"
    import {createFloatingActions} from "svelte-floating-ui"
    import {flip, offset, shift} from "svelte-floating-ui/dom"
    import TimePicker from "@shared/Form/TimeType/TimePicker.svelte"
    import {DateTime} from "luxon"

    export let entry: FormEntry
    export let autofocus = false

    const dispatch = createEventDispatcher()

    let inputEl: HTMLInputElement
    let focused = false
    let pickingTime = false

    const [ floatingRef, floatingContent ] = createFloatingActions({
        strategy: "absolute",
        autoUpdate: true,
        placement: "bottom-end",
        middleware: [
            flip(),
            shift(),
        ],
    });

    const handleInputValueChange = (): void => {
        if(entry.disabled) {
            return
        }

        if(pickingTime) {
            return
        }

        if (!/\d/.test(inputEl.value)) {
            if (!focused) {
                entry.value = ""
            }
            return
        }

        const parts = inputEl.value.split(":")

        let hours = parseInt(parts[0])
        let minutes = parseInt(parts[1] ?? "")

        if (!isNaN(hours) && isNaN(minutes)) {
            let raw = parts[0].substring(0, 4)
            if (raw.length === 4) {
                hours = parseInt(raw.substring(0, 2))
                minutes = parseInt(raw.substring(2, 4))
            }

            if (raw.length === 3) {
                hours = parseInt(raw.substring(0, 1))
                minutes = parseInt(raw.substring(1, 3))
            }
        }

        if (!isNaN(hours)) {
            if (hours < 0) {
                hours = 0
            }

            if (hours > 23) {
                hours = 23
            }
        } else {
            hours = 0
        }

        if (!isNaN(minutes)) {
            if (minutes < 0) {
                minutes = 0
            }

            if (minutes > 59) {
                minutes = 59
            }
        } else {
            minutes = 0
        }

        entry.value = hours.toString().padStart(2, '0')+":"+minutes.toString().padStart(2, "0")
    }

    const handleEnter = (e: KeyboardEvent): void => {
        if(entry.disabled) {
            return
        }

        if (e.code === "Enter") {
            e.preventDefault()
            e.stopPropagation()

            focused = false
            handleInputValueChange()
        }
    }

    $: {
        pickingTime

        if (inputEl && !entry.disabled) {
            if(entry.value === "") {
                entry.value = DateTime.now().toFormat("HH:mm")
            }

            handleInputValueChange()
        }
    }

    const handleBlur = (): void => {
        if(entry.disabled) {
            return
        }

        if(focused || pickingTime) {
            focused = false
            pickingTime = false
            handleInputValueChange()
        }
    }

    onMount(() => {
        if (!entry.disabled && autofocus) {
            autofocus = false
            inputEl.focus()
        }
    })
</script>

{#if !(entry.disabled && entry.value === "")}
    <div class="flex flex-col flex-1 relative">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}

        <div class="relative {entry.disabled ? '' : 'bg-white'} rounded-md mt-1" use:floatingRef>
            <input
                    bind:this={inputEl}
                    name={entry.fullName}
                    autocomplete="off"
                    class="bg-white rounded-md shadow-sm px-4 py-2 w-full pr-14 focus-visible:outline-0 focus-visible:shadow-md {entry.disabled ? '!bg-transparent !shadow-none !px-0 text-neutral-600' : ''}"
                    disabled={entry.disabled ? 'disabled' : null}
                    on:focus={() => focused = true}
                    on:blur={handleBlur}
                    required={entry.required ? 'required' : null}
                    type="text"
                    value={entry.value ?? ""}
                    placeholder={entry.disabled ? null : entry.attr?.placeholder ?? null}
                    on:keypress={handleEnter}
            />
            {#if !entry.disabled}
                <button class="absolute right-0 top-0 bottom-0 px-3"
                        tabindex="-1"
                        use:tooltip={{content: "Vybrat čas"}}
                        on:click|preventDefault={() => pickingTime = !pickingTime}
                >
                    <img src="{pickingTime ? '/image/delete.svg' : '/image/clock.svg'}" class="w-5 h-5" alt="pick time icon"/>
                </button>
                {#if pickingTime}
                    <div class="absolute py-1 z-10"
                         use:floatingContent
                         use:clickOutside={() => setTimeout(() => pickingTime = false, 10)}
                    >
                        <TimePicker bind:value={entry.value}/>
                    </div>
                {/if}
            {/if}
        </div>

        <FormTypeErrors errors={entry.errors}/>
    </div>
{/if}
