<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import SelectType from "@shared/Form/SelectType/SelectType.svelte"
    import TextType from "@shared/Form/TextType/TextType.svelte"

    export let entry: FormEntry
</script>


{#if !(entry.disabled && (entry.value === "" || entry.value.number === ""))}
    <div class={entry.rowAttr["class"] ?? "flex flex-col items-center gap-x-3"}>
        {#if entry.label !== null}
            <label {...entry.labelAttr} class={entry.labelAttr["class"] ?? "font-bold text-neutral-800 relative"}>
                {@html entry.label}
                {#if entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}

        <div class="grid grid-cols-1 md:grid-cols-2 gap-3 items-center">
            <SelectType entry={entry.children["country"]}/>
            <TextType entry={entry.children["number"]}/>
        </div>

        <FormTypeErrors errors={entry.errors}/>
    </div>
{/if}
