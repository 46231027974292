<script lang="ts">
    import {DateTime, HourNumbers, MinuteNumbers} from "luxon"

    export let value: string|null

    const extractInt = (val: string|null, defaultVal: number, min: number, max: number): number => {
        if(val === null) {
            return defaultVal
        }

        let parsed = parseInt(val)
        if(isNaN(parsed)) {
            parsed = min
        }

        return Math.max(Math.min(max, parsed), min)
    }

    const spinnerSize = 3
    let hour = extractInt(value ? value.slice(0, 2) : null, DateTime.now().hour, 0, 23)
    let minute = extractInt(value ? value.slice(-2) : null, DateTime.now().minute, 0, 59)
    let hours: number[]
    let minutes: number[]


    const setHour = (target: number): void => {
        const replacement = Math.max(Math.min(24, target), -1)
        if(replacement >= 24) {
            hour = 0
            return
        }

        if(replacement <= -1) {
            hour = 23
            return
        }

        hour = replacement as HourNumbers
    }

    const setMinute = (target: number): void => {
        const replacement = Math.max(Math.min(60, target), -1)
        if(replacement >= 60) {
            setHour(hour + 1)
            minute = 0
            return
        }

        if(replacement <= -1) {
            setHour(hour - 1)
            minute = 59
            return
        }

        minute = replacement as MinuteNumbers
    }

    const handleWheel = (event: WheelEvent, type: "hour" | "minute"): void => {
        const isUp = event.deltaY < 0
        const step = event.shiftKey ? 5 : 1

        if(type === "hour") {
            setHour(hour + (isUp ? -step : step))
        }

        if(type === "minute") {
            setMinute(minute + (isUp ? -step : step))
        }
    }

    $: value = `${(hour ?? "0").toString().padStart(2, "0")}:${(minute ?? "0").toString().padStart(2, "0")}`

    $: {
        const parts = (value ?? "").split(":").map(part => {
            part = part.trim()
            let parsed = parseInt(part)
            if(isNaN(parsed)) {
                parsed = 0
            }

            return parsed
        })

        if(parts[0] !== hour) {
            setHour(parts[0] ?? hour)
        }
        if(parts[1] !== minute) {
            setMinute(parts[1] ?? minute)
        }
    }


    $: {
        const spinnerHours = []
        const spinnerMinutes = []

        // x before
        for (let i = spinnerSize; i > 0; i--) {
            let targetHour = hour - i
            if(targetHour < 0) {
                targetHour = Math.abs(24 + targetHour)
            }
            spinnerHours.push(targetHour)

            let targetMinute = minute - i
            if(targetMinute < 0){
                targetMinute = Math.abs(60 + targetMinute)
            }
            spinnerMinutes.push(targetMinute)
        }

        spinnerHours.push(hour)
        spinnerMinutes.push(minute)

        // x after :D
        for (let i = 1; i <= spinnerSize; i++) {
            let targetHour = hour + i
            if(targetHour >= 24) {
                targetHour = Math.abs(24 - targetHour)
            }
            spinnerHours.push(targetHour)

            let targetMinute = minute + i
            if(targetMinute >= 60){
                targetMinute = Math.abs(60 - targetMinute)
            }
            spinnerMinutes.push(targetMinute)
        }


        hours = spinnerHours
        minutes = spinnerMinutes
    }
</script>

<div class="flex flex-row items-center gap-x-2.5 w-32 border border-neutral-200 justify-center py-4 bg-white rounded-md shadow-lg">
    <div class="grid grid-rows-[10] w-7" on:wheel|preventDefault={(event) => handleWheel(event, "hour")}>
        <button class="pb-2.5" on:click|preventDefault={() => setHour(hour - 1)}>
            <svg class="w-3.5 h-3.5 mx-auto hover:scale-[1.35] rotate-90 hover:fill-primary transform-gpu transition-all" width="170.6" height="297.4" version="1.1" viewBox="0 0 7.997 13.94" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-7.999 -5.06)" data-name="17">
                    <path d="m15 19a1 1 0 0 1-0.71-0.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1-0.7 1.71z"/>
                </g>
            </svg>
        </button>

        {#each hours as sHour}
            <button class="text-neutral-800 py-1.5 transition-all spinner-number text-sm"
                    on:click|preventDefault={() => setHour(sHour)}
            >
                {sHour.toString().padStart(2, "0")}
            </button>
        {/each}

        <button class="pt-2.5" on:click|preventDefault={() => setHour(hour + 1)}>
            <svg class="w-3.5 h-3.5 mx-auto hover:scale-[1.35] -rotate-90 hover:fill-primary transform-gpu transition-all" width="170.6" height="297.4" version="1.1" viewBox="0 0 7.997 13.94" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-7.999 -5.06)" data-name="17">
                    <path d="m15 19a1 1 0 0 1-0.71-0.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1-0.7 1.71z"/>
                </g>
            </svg>
        </button>
    </div>
    <div>
        <span class="font-semibold">:</span>
    </div>
    <div class="grid grid-rows-[10] w-7" on:wheel|preventDefault={(event) => handleWheel(event, "minute")}>
        <button class="pb-2.5" on:click|preventDefault={() => setMinute(minute - 1)}>
            <svg class="w-3.5 h-3.5 mx-auto hover:scale-[1.35] rotate-90 hover:fill-primary transform-gpu transition-all" width="170.6" height="297.4" version="1.1" viewBox="0 0 7.997 13.94" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-7.999 -5.06)" data-name="17">
                    <path d="m15 19a1 1 0 0 1-0.71-0.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1-0.7 1.71z"/>
                </g>
            </svg>
        </button>

        {#each minutes as sMinute}
            <button class="text-neutral-800 py-1.5 transition-all spinner-number text-sm"
                    on:click|preventDefault={() => setMinute(sMinute)}
            >
                {sMinute.toString().padStart(2, "0")}
            </button>
        {/each}

        <button class="pt-2.5" on:click|preventDefault={() => setMinute(minute + 1)}>
            <svg class="w-3.5 h-3.5 mx-auto hover:scale-[1.35] -rotate-90 hover:fill-primary transform-gpu transition-all" width="170.6" height="297.4" version="1.1" viewBox="0 0 7.997 13.94" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-7.999 -5.06)" data-name="17">
                    <path d="m15 19a1 1 0 0 1-0.71-0.29l-6-6a1 1 0 0 1 0-1.41l6-6a1 1 0 0 1 1.41 1.41l-5.29 5.29 5.29 5.29a1 1 0 0 1-0.7 1.71z"/>
                </g>
            </svg>
        </button>
    </div>
</div>

<style lang="postcss">
    div .spinner-number:nth-child(5) {
        @apply font-semibold text-xl;
    }
    div .spinner-number:not(:nth-child(5)) {
        @apply hover:text-primary hover:scale-125;
    }
</style>