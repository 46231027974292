<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"

    export let entry: FormEntry
</script>

{#if !(entry.disabled && entry.value === "")}
    <div {...entry.rowAttr} class="flex flex-col">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}
        <div class="flex flex-row mt-1 {entry.disabled ? '' : 'shadow-sm'} focus-within:shadow-md rounded-md">
            <input
                    {...entry.attr}
                    autocomplete="off"
                    disabled={entry.disabled ? 'disabled' : null}
                    id={entry.id}
                    class="bg-white rounded-l-md px-4 py-2 focus-visible:outline-0 w-full {entry.disabled ? 'hidden' : ''}"
                    name={entry.fullName}
                    on:input={e => entry.value = e.target.value}
                    on:input
                    required={entry.required ? 'required' : null}
                    type={entry.type}
                    value={entry.value}
            />
            <div class="flex items-center {entry.disabled ? '' : 'bg-neutral-200 px-3.5'} rounded-r-md">
                {#if entry.disabled}
                    <span class="mr-1">{entry.value}</span>
                {/if}
                {#if !entry.disabled || (entry.disabled && String(entry.value ?? '').length > 0)}
                    <b class:text-sm={entry.disabled}>
                        {entry.extra.unit}
                    </b>
                {/if}
            </div>
        </div>

        <FormTypeErrors errors={entry.errors}/>
    </div>
{/if}
