<script lang="ts">
    import { Turnstile } from "svelte-turnstile"
    import {FormEntry} from "@shared/Form/interface"

    export let entry: FormEntry<any, {
        siteKey: string
    }>
</script>

<div class="hidden">
    <Turnstile siteKey={entry.extra.siteKey}
               action={entry.attr["data-action"]}
               formsField="cf-turnstile-response"
    />
</div>