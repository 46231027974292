import {FormEntry} from "@shared/Form/interface"
import {cloneDeep as _cloneDeep} from "lodash-es"

const prototypeReplacementFields = [
    "fullName",
    "id",
    "label",
    "name"
]

export const replacePrototypeNamesWithIndex = (formEntry: FormEntry, prototypeName: string, index: string): FormEntry => {
    prototypeReplacementFields.forEach((field) => {
        // @ts-ignore
        formEntry[field] = formEntry[field]?.replace(prototypeName, index)
    })

    if (!formEntry.children) {
        formEntry.children = []
    }

    if (Array.isArray(formEntry.children)) {
        formEntry.children = formEntry.children.map(child => replacePrototypeNamesWithIndex(child, prototypeName, index))
    } else {
        Object.keys(formEntry.children).forEach(key => {
            // @ts-ignore
            formEntry.children[key] = replacePrototypeNamesWithIndex(formEntry.children[key], prototypeName, index)
        })

        formEntry.children = formEntry.children
    }

    return formEntry
}

export const handleAddChildToCollection = (entry: FormEntry): FormEntry[] => {
    entry.extra.counter++

    const childTemplate = replacePrototypeNamesWithIndex(
        _cloneDeep(entry.extra.prototype),
        entry.extra.prototype.name,
        entry.extra.counter.toString()
    )

    if (!entry.children) {
        entry.children = []
    }

    // @ts-ignore
    return [...entry.children, childTemplate]
}

export const handleRemoveChildFromCollection = (children: FormEntry<any, any, any, any>[], indexToRemove: number): FormEntry[] => {
    return children.filter((_, index) => index !== indexToRemove)
}
