<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import TextType from "@shared/Form/TextType/TextType.svelte"
    import ColorType from "@shared/Form/ColorType/ColorType.svelte"
    import AddressType from "@shared/Form/AddressType/AddressType.svelte"
    import CheckboxType from "@shared/Form/CheckboxType/CheckboxType.svelte"
    import HiddenType from "@shared/Form/HiddenType/HiddenType.svelte"
    import SelectType from "@shared/Form/SelectType/SelectType.svelte"
    import TextareaType from "@shared/Form/TextareaType/TextareaType.svelte"
    import MediaUploadType from "@shared/Form/MediaUploadType/MediaUploadType.svelte"
    import FormChildren from "@shared/Form/FormChildren.svelte"
    import TimeType from "@shared/Form/TimeType/TimeType.svelte"
    import UnitType from "@shared/Form/UnitType/UnitType.svelte"
    import PasswordType from "@shared/Form/PasswordType/PasswordType.svelte"
    import PricingSelectionType from "@shared/Form/PricingSelectionType/PricingSelectionType.svelte"
    import PhoneType from "@shared/Form/PhoneType/PhoneType.svelte"
    import WeatherTypeSelectType from "@shared/Form/WeatherTypeSelectType/WeatherTypeSelectType.svelte"
    import CalendarDateType from "@shared/Form/CalendarDateType/CalendarDateType.svelte"
    import TurnstileType from "@shared/Form/TurnstileType/TurnstileType.svelte"
    import CollectionType from "@shared/Form/CollectionType/CollectionType.svelte"

    export let form: FormEntry
    export let blacklist: string[] = []

    $: children = (form.children !== null
            ? Array.isArray(form.children)
                    ? form.children
                    : Object.values(form.children)
            : []).filter(child => !blacklist.includes(child.name))
</script>

<!--TODO: swap with dynamic form type imports-->
{#each children as child (child.fullName)}
    {#if child.type === "color"}
        <ColorType entry={child}/>
    {:else if child.type === "password"}
        <PasswordType entry={child}/>
    {:else if child.type === "pricing_selection"}
        <PricingSelectionType entry={child}/>
    {:else if child.type === "address"}
        <AddressType entry={child}/>
    {:else if child.type === "checkbox"}
        <CheckboxType entry={child}/>
    {:else if child.type === "hidden"}
        <HiddenType entry={child}/>
    {:else if child.type === "phone_number"}
        <PhoneType entry={child}/>
    {:else if child.type === "unit"}
        <UnitType entry={child}/>
    {:else if child.type === "turnstile"}
        <TurnstileType entry={child}/>
    {:else if child.type === "calendar_date"}
        <CalendarDateType entry={child}/>
    {:else if child.type === "weather_type_select"}
        <WeatherTypeSelectType entry={child}/>
    {:else if child.type === "textarea"}
        <TextareaType entry={child}/>
    {:else if child.type === "immutable_time"}
        <TimeType entry={child}/>
    {:else if child.type === "media_upload"}
        <MediaUploadType entry={child}/>
    {:else if ["entity", "country", "enum",  "select", "choice"].includes(child.type)}
        <SelectType entry={child}/>
    {:else if child.type.endsWith("creatable_entity")}
        <SelectType entry={child}/>
    {:else if child.type.endsWith("extensible_entity")}
        <SelectType entry={child}/>
    {:else if child.type.endsWith("extensible_choice")}
        <SelectType entry={child}/>
    {:else if ["collection"].includes(child.type)}
        <CollectionType entry={child}/>
    {:else if ["repeated", "collection", "drawing_point"].includes(child.type)}
        <FormChildren form={child}/>
    {:else}
        <TextType entry={child}/>
    {/if}
{/each}
